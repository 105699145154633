<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout-header class="layhead">
        <top-bar msg="" showback="0" />
      </a-layout-header>
      <a-layout>
        <sider-bar />
        <a-layout-content class="adminalcontent">
          <div class="maindiv">
            <a-breadcrumb class="bread">
              <a-breadcrumb-item>
                <router-link to="/index"> 控制台 </router-link>
              </a-breadcrumb-item>
              <a-breadcrumb-item> 账号人员管理 </a-breadcrumb-item>
            </a-breadcrumb>

            <div class="searchbar">
              <a-row>
                <a-col :span="2" class="title"> 查询条件 </a-col>
                <a-col :span="22">
                  <a-input-search
                    placeholder="关键字搜索"
                    v-model="query.searchkey"
                    enter-button
                    style="width: 200px"
                    @search="onSearch"
                  />

                  <a-select
                    v-model="query.level"
                    style="width: 120px; margin-left: 10px"
                    @change="onSearch"
                  >
                    <a-select-option key=""> 选择等级 </a-select-option>
                    <a-select-option key="1" v-if="logininfo.ksa_level == 0">
                      管理员
                    </a-select-option>
                    <!-- <a-select-option key="2" v-if="logininfo.ksa_level <= 1">
                      市级管理员
                    </a-select-option>
                    <a-select-option key="3" v-if="logininfo.ksa_level <= 2">
                      机构管理员
                    </a-select-option>
                    <a-select-option key="4" v-if="logininfo.ksa_level <= 3">
                      考评人
                    </a-select-option> -->
                  </a-select>

                  <a-select
                    v-model="query.citycode"
                    @change="handleCityChangef"
                    style="width: 120px; margin-left: 10px"
                  >
                    <a-select-option key=""> 选择城市 </a-select-option>
                    <a-select-option v-for="city in citys" :key="city.adcode">
                      {{ city.name }}
                    </a-select-option>
                  </a-select>

                  <a-select
                    v-model="query.unid"
                    @change="onSearch"
                    style="width: 300px; margin-left: 10px"
                  >
                    <a-select-option key=""> 选择机构 </a-select-option>
                    <a-select-option v-for="unit in unitlistf" :key="unit.id">
                      {{ unit.title }}（{{ unit.no }}）
                    </a-select-option>
                  </a-select>
                </a-col>
              </a-row>
            </div>

            <a-row>
              <a-col :span="8" class="tabletools">
                <a-button
                  v-if="thisrule > 1"
                  type="primary"
                  @click="add"
                  style="margin-right: 10px"
                >
                  <!-- {{showAdd(false)}} -->
                  <a-icon type="plus" /> 添加
                </a-button>
              </a-col>
              <a-col :span="4"> </a-col>
              <a-col :span="4"> </a-col>
            </a-row>

            <a-table
              size="small"
              class="mtable"
              :loading="loading"
              :pagination="pagination"
              :columns="columns"
              :data-source="data"
              @change="handleTableChange"
            >
              <span
                slot="indexText"
                class="nmitem"
                slot-scope="text, record, index"
                >{{ index + 1 }}</span
              >
              <span slot="normalText" class="nmitem" slot-scope="text">{{
                text
              }}</span>
              <span slot="nameText" class="nmitem" slot-scope="text, record">
                {{ record.username }}
                <template v-if="record.realname != ''">
                  <p style="margin-left: 0px; font-size: 12px">
                    <b>姓名:{{ record.realname }}</b>
                  </p>
                </template>
              </span>
              <span slot="levelText" class="nmitem" slot-scope="text">
                <template v-if="text == '0'">
                  <b style="color: #ff0000">超级管理员</b>
                </template>
                <template v-if="text == '1'">
                  <b style="color: #333333">管理员</b>
                </template>
                <template v-if="text == '2'">
                  <b style="color: #666666">员工</b>
                </template>
                <!-- <template v-if="text == '3'">
                  <b style="color: #007fbb">机构管理员</b>
                </template>
                <template v-if="text == '4'">
                  <b style="color: #00bb16">考评人</b>
                </template> -->
              </span>

              <span slot="cityText" class="nmitem" slot-scope="text, record">
                <template v-if="record.city != ''">
                  <b style="color: #000000">{{ record.city }}</b>
                  <template v-if="record.discode != ''">
                    -{{ record.dis }}
                  </template>
                </template>
                <template v-if="record.unittitle != ''">
                  ({{ record.unittitle }})
                </template>
              </span>

              <span slot="photoarea" class="photo" slot-scope="text">
                <img :src="text" v-if="text != ''" />
                <img v-else src="../assets/user.png" />
              </span>

              <b
                slot="blodText"
                class="nmitem"
                slot-scope="text"
                style="color: #000; font-size: 16px"
                >{{ text }}</b
              >

              <span slot="action" slot-scope="text, record">
                <template v-if="thisrule == 3">
                  <a @click="onPassword(record)">修改密码</a>
                  <template v-if="record.level > 0">
                    <a-divider type="vertical" />
                    <a @click="onEdit(record)">修改</a>
                    <a-divider type="vertical" />
                    <a @click="onDel(record.id)">删除</a>
                    <br />
                    <a @click="onRecheck(record.id)">重置登录</a>
                  </template>
                </template>
              </span>
            </a-table>

            <a-modal
              :title="modaltitle"
              :visible="visibleAddModal"
              :confirm-loading="confirmLoading"
              ok-text="确认"
              cancel-text="取消"
              @ok="handleAddOk"
              @cancel="handleAddCancel"
              :width="800"
            >
              <a-form :form="form" class="myform">
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="照片"
                >
                  <a-input hidden v-decorator="['photourl']" placeholder="" />
                  <a-upload
                    action=""
                    list-type="picture-card"
                    :show-upload-list="false"
                    :before-upload="
                      (file) => {
                        beforeImgUpload(file);
                      }
                    "
                  >
                    <img
                      v-if="imageUrl"
                      class="tmimage"
                      :src="imageUrl"
                      alt="avatar"
                    />
                    <div v-else>
                      <a-icon :type="loading ? 'loading' : 'plus'" />
                      <div class="ant-upload-text">上传图片</div>
                    </div>
                  </a-upload>
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="用户名"
                >
                  <a-input
                    style="width: 300px"
                    v-decorator="[
                      'username',
                      {
                        rules: [{ required: true, message: '请填写用户名' }],
                      },
                    ]"
                    oninput="if(value.length > 20)value = value.slice(0, 20)"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="姓名"
                >
                  <a-input
                    style="width: 300px"
                    v-decorator="[
                      'realname',
                      {
                        rules: [{ required: true, message: '请填写姓名' }],
                      },
                    ]"
                    oninput="if(value.length > 20)value = value.slice(0, 20)"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  v-if="passwordshow"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="密码"
                >
                  <a-input-password
                    style="width: 300px"
                    v-decorator="[
                      'password',
                      { rules: [{ required: true, message: '请设置密码' }] },
                    ]"
                    oninput="if(value.length > 32)value = value.slice(0, 32)"
                    placeholder=""
                  />
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="手机号码"
                >
                  <a-input
                    style="width: 300px"
                    v-decorator="[
                      'phone',
                      {
                        rules: [{ required: true, message: '请填写手机号码' }],
                      },
                    ]"
                    oninput="if(value.length > 11)value = value.slice(0, 11)"
                    placeholder=""
                  />
                  (手机短信验证登录)
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="锁定"
                >
                  <a-checkbox
                    v-decorator="['lock', { valuePropName: 'checked' }]"
                  >
                  </a-checkbox>
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="角色"
                >
                  <a-radio-group
                    v-decorator="[
                      'level',
                      {
                        rules: [{ required: true, message: '请填选择角色' }],
                      },
                    ]"
                    @change="handleLevelChange"
                  >
                    <a-radio-button value="1" v-if="logininfo.ksa_level == 0">
                      管理员
                    </a-radio-button>
                    <a-radio-button value="2" v-if="logininfo.ksa_level <= 1">
                      员工
                    </a-radio-button>
                    <!-- <a-radio-button value="3" v-if="logininfo.ksa_level <= 2">
                      机构管理员
                    </a-radio-button>
                    <a-radio-button value="4" v-if="logininfo.ksa_level <= 3">
                      考评人
                    </a-radio-button> -->
                  </a-radio-group>
                </a-form-item>


                <!-- <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="所属单位"
                >
                  <a-select
                    v-decorator="[
                      'unid',
                      {
                        initialValue: unid,
                        rules: [{ required: true, message: '请选择机构' }],
                      },
                    ]"
                    style="width: 90%"
                    @change="UnitChange"
                  >
                    <a-select-option v-for="unit in unitlist" :key="unit.id">
                      {{ unit.title }}（{{ unit.no }}）
                    </a-select-option>
                  </a-select>
                </a-form-item> -->

                <!-- <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="职位"
                >
                  <a-tree-select
                    v-decorator="[
                      'mcode',
                      {
                        initialValue: mcode,
                        rules: [{ required: true, message: '请选择职位' }],
                      },
                    ]"
                    style="width: 90%"
                    :tree-data="treeData"
                    search-placeholder="请选择"
                    @change="TDhandleChange"
                    tree-default-expand-all
                    :allowClear="true"
                  />
                </a-form-item> -->

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="权限管理"
                >
                  <a-table
                    size="small"
                    class="mtable"
                    :loading="loading"
                    :columns="sidercolumns"
                    :data-source="siderdata"
                    :pagination="false"
                  >
                    <span
                      slot="titletext"
                      class="nmitem"
                      slot-scope="text, record"
                    >
                      <template v-if="record.main">
                        <b>{{ text }}</b>
                      </template>
                      <template v-else>
                        <span style="padding-left: 24px">{{ text }}</span>
                      </template>
                    </span>

                    <span slot="keytext" class="nmitem" slot-scope="text">
                      {{ text }}
                    </span>
                    <span slot="rule" class="nmitem" slot-scope="text, record">
                      <a-radio-group
                        v-if="record.radio"
                        size="small"
                        :value="text"
                        button-style="solid"
                        @change="
                          (e) => {
                            ruleonchange(e, record.key);
                          }
                        "
                      >
                        <template v-if="record.key == 'membermag/dispense'">
                          <a-radio-button value="3"> 完全控制 </a-radio-button>
                          <a-radio-button value="2">
                            随机分配机构
                          </a-radio-button>
                          <a-radio-button value="1"> 查看 </a-radio-button>
                          <a-radio-button value="0"> 无权限 </a-radio-button>
                        </template>
                        <template v-else>
                          <a-radio-button value="3"> 完全控制 </a-radio-button>
                          <a-radio-button value="2"> 添加 </a-radio-button>
                          <a-radio-button value="1"> 查看 </a-radio-button>
                          <a-radio-button value="0"> 无权限 </a-radio-button>
                        </template>
                      </a-radio-group>
                    </span>
                  </a-table>
                </a-form-item>
              </a-form>
            </a-modal>

            <a-modal
              title="修改密码"
              :visible="visiblePasswordModal"
              :confirm-loading="confirmLoading"
              ok-text="确认"
              cancel-text="取消"
              @ok="handleChangePassworOk"
              @cancel="handleChangePasswordCancel"
              :width="460"
            >
              <a-form :form="form2" class="myform">
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="密码"
                >
                  <a-input-password
                    v-decorator="[
                      'password',
                      { rules: [{ validator: checkPassword }] },
                    ]"
                    oninput="if(value.length > 32)value = value.slice(0, 32)"
                    placeholder=""
                  />
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="重复"
                >
                  <a-input-password
                    v-decorator="[
                      'password2',
                      { rules: [{ validator: checkPassword2 }] },
                    ]"
                    oninput="if(value.length > 32)value = value.slice(0, 32)"
                    placeholder=""
                  />
                </a-form-item>
              </a-form>
            </a-modal>
          </div>
          <a-layout-footer class="layfoot">
            <footer-bar />
          </a-layout-footer>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import {
  addUser,
  fetchUser,
  delUser,
  reUserCheck,
  updateUser,
  uploadImage,
  updatePassword,
  fetchCity,
  fetchUnit,
  fetchClass,
  fetchTreeClass,
  fetchTreeOrg,
} from "../api/index";
// const Base64 = require('js-base64').Base64
import global from "../common";
import TopBar from "../components/TopBar.vue";
import SiderBar from "../components/Sider.vue";
import FooterBar from "../components/FooterBar.vue";
import districts from "../utils/json";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const columns = [
  {
    title: "序号",
    dataIndex: "id",
    width: 60,
    scopedSlots: { customRender: "indexText" },
  },
  // {
  //   title: "ID",
  //   dataIndex: "id",
  //   width: 120,
  //   scopedSlots: { customRender: "normalText" },
  // },
  {
    title: "照片",
    dataIndex: "photourl",
    align: "center",
    width: 140,
    scopedSlots: { customRender: "photoarea" },
  },
  {
    title: "用户名",
    dataIndex: "username",
    scopedSlots: { customRender: "nameText" },
  },
  {
    title: "等级",
    dataIndex: "level",
    scopedSlots: { customRender: "levelText" },
  },
  // {
  //   title: "所属",
  //   dataIndex: "city",
  //   scopedSlots: { customRender: "cityText" },
  // },
  // {
  //   title: "职位",
  //   dataIndex: "orgname",
  //   scopedSlots: { customRender: "normalText" },
  // },
  {
    title: "添加时间",
    dataIndex: "addtime",
    scopedSlots: { customRender: "normalText" },
  },
  {
    title: "添加人",
    dataIndex: "uname",
    // width: 120,
    scopedSlots: { customRender: "normalText" },
  },
  {
    title: "操作",
    key: "action",
    width: 160,
    scopedSlots: { customRender: "action" },
  },
];

const innerColumns = [
  {
    title: "文件名",
    dataIndex: "name",
  },
  {
    title: "文件",
    dataIndex: "url",
    scopedSlots: { customRender: "files" },
  },
];

const sidercolumns = [
  {
    title: "目录",
    dataIndex: "title",
    scopedSlots: { customRender: "titletext" },
  },
  {
    title: "KEY",
    dataIndex: "key",
    scopedSlots: { customRender: "keytext" },
  },
  {
    title: "权限",
    dataIndex: "rule",
    align: "right",
    scopedSlots: { customRender: "rule" },
  },
];

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const formTailLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 10, offset: 6 },
};

export default {
  name: "teammag",
  components: {
    TopBar,
    FooterBar,
    SiderBar,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      citycode: "",
      cityData: [],
      unitlist: [],
      unitlistf: [],
      unid: "",
      visiblePasswordModal: false,

      sidercolumns,
      siderdata: [],

      imageUrl: "",
      uploading: false,

      fileList: [
        // {
        //   uid: '-1',
        //   name: 'xxx.png',
        //   status: 'done',
        //   url: 'http://www.baidu.com/xxx.png',
        // },
      ],

      modaltitle: "添加",
      data: [],
      columns,
      innerColumns,
      pagination: {},
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      // query: {
      //   address: "",
      //   name: "",
      //   pageIndex: 1,
      //   pageSize: 10,
      // },
      tableData: [],
      pageTotal: 0,
      pageSize: 12,
      page: 1,

      visibleAddModal: false,
      confirmLoading: false,

      form2: this.$form.createForm(this, { name: "Password" }),

      form: this.$form.createForm(this, { name: "Team" }),
      formItemLayout,
      formTailLayout,
      action: "add",
      editid: 0,
      classlist: [],
      classlist2: [],
      class1: 0,
      class2: 0,

      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 20, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      },

      password: "",

      passwordshow: true,

      thisrule: 0,

      query: {
        searchkey: "",
        citycode: "",
        level: "",
        city: "",
        unid: "",
      },

      logininfo: [],

      provinces: [],
      citycode: "",
      cityname: "",
      citys: [],
      discode: "",
      disname: "",
      diss: [],

      treevalue: "",

      level: 1,

      treeData: [],

      mcode: "",
    };
  },
  mounted() {
    this.fetch();
    this.thisrule = global.checkrule();
    this.getClassList();
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  created() {
    // this.setsiderdata();
    this.logininfo = global.getLoginA();
    console.log("logininfo:", this.logininfo);
    // this.getcity();
    this.gettreeclass();
    this.getprovinces();
    this.handleProChange("210000");
  },
  methods: {
    UnitChange(e) {
      this.gettreeclass(e);
      this.treevalue = "";
      this.form.setFieldsValue({
        mcode: "",
      });
    },

    gettreeclass(unid = 0) {
      let that = this;
      this.loading = true;
      fetchTreeOrg({ unid }).then((res) => {
        console.log("fetchTreeOrg:", res);
        that.loading = false;
        this.treeData = res.results.list;
      });
    },

    TDhandleChange(value) {
      console.log(`TDhandleChange:`, value);
    },

    handleProChange(e) {
      console.log("handleProChange", e);
      this.provinces.map((item, index) => {
        if (item.adcode == e) {
          this.citys = item.districts;
          console.log("this.logininfo.citycode:", this.logininfo.citycode);
          if (
            this.logininfo.citycode != "" &&
            this.logininfo.citycode != undefined
          ) {
            this.citys = item.districts.filter((item) => {
              // console.log('item.adcode:',item.adcode,this.logininfo.ksa_citycode);
              return item.adcode == this.logininfo.ksa_citycode;
            });
          }
        }
      });
      this.cityname = "";
      this.disname = "";
      console.log("citys:", this.citys);
      setTimeout(() => {
        this.form.setFieldsValue({
          citycode: "",
          discode: "",
        });
      });
    },
    handleCityChange(e, node, unid = "") {
      console.log("unid:", e, unid);
      this.citycode = e;

      this.citys.map((item) => {
        if (item.adcode == e) {
          this.diss = item.districts;
          this.cityname = item.name;

          if (
            this.logininfo.discode != "" &&
            this.logininfo.discode != undefined
          ) {
            this.diss = item.districts.filter((item) => {
              return item.adcode == this.logininfo.ksa_discode;
            });
          }
        }
      });

      this.disname = "";
      this.discode = "";

      setTimeout(() => {
        this.form.setFieldsValue({
          discode: "",
        });
        if (this.level > 2) {
          this.unitlist = [];
          this.fetchUnit();
          this.form.setFieldsValue({
            unid: unid,
          });
        }
      });
    },
    handleDisChange(e, node = [], unid = "") {
      this.discode = e;
      this.diss.map((item) => {
        if (item.adcode == e) {
          this.discode = item.adcode;
          this.disname = item.name;
        }
      });

      setTimeout(() => {
        this.form.setFieldsValue({
          discode: this.discode,
        });
        this.unitlist = [];
        this.fetchUnit();
        if (this.level > 2) {
          this.form.setFieldsValue({
            unid: unid,
          });
        }
      });
    },
    getprovinces() {
      this.provinces = districts;
    },

    getClassList() {
      const that = this;
      fetchClass().then((res) => {
        console.log(
          "%cfetchClass",
          "color: green;font-size: 14px;font-weight: bold;",
          res
        );
        that.classlist = res.results.list;
      });
    },
    fetchUnit(
      citycode = "",
      unid = "",
      params = {
        pagesize: 10000,
        kind: 1,
        query: {
          citycode: this.citycode,
          discode: this.discode,
        },
      }
    ) {
      console.log("params:", params);
      let that = this;
      fetchUnit(params).then((res) => {
        console.log("fetchUnit:", res);
        this.unitlist = res.results.list;
        if (this.unitlist != undefined) {
          this.unid = this.unitlist[0]["id"];
        } else {
          this.unid = "";
        }
        // alert(this.unid);

        if (citycode != "") {
          this.citycode = citycode;
        }
        if (unid != "" && unid != "0") {
          this.unid = unid;
        }

        if (
          that.form.getFieldValue("level") == "3" ||
          that.form.getFieldValue("level") == "4"
        ) {
          that.form.setFieldsValue({
            // unid: this.unid,
          });
        }
      });
    },

    fetchUnitf(
      params = {
        pagesize: 10000,
        kind: 1,
        query: {
          citycode: this.query.citycode,
        },
      }
    ) {
      console.log("params:", params);
      let that = this;
      fetchUnit(params).then((res) => {
        console.log("fetchUnitf:", res);
        this.unitlistf = res.results.list;
        this.query.unid = "";
        that.fetch();
      });
    },

    getcity() {
      let that = this;
      fetchCity().then((res) => {
        console.log("fetchCity", res);
        let citylist = res.results.list;
        if (citylist.length > 0) {
          // let cityData = [];
          // citylist.map((item) => {
          //   if (this.logininfo.ksa_level > 1) {
          //     if (this.logininfo.ksa_city == item.city) {
          //       cityData.push(item.city);
          //     }
          //   } else {
          //     cityData.push(item.city);
          //   }
          // });
          that.cityData = citylist;
          that.citycode = citylist[0]["citycode"];
          that.fetchUnit();
        }
      });
    },
    // handleCityChange(value) {
    //   this.citycode = value;
    //   console.log("citycode:", this.citycode);
    //   this.fetchUnit();
    // },

    handleCityChangef() {
      this.fetchUnitf();
    },
    handleLevelChange(e) {
      let level = e.target.value;
      this.level = level;
      console.log("level:", level);
      setTimeout(() => {
        this.setsiderdata();
        this.diss = [];
        // this.unitlist = [];
        this.form.setFieldsValue({
          citycode: "",
          discode: "",
          unid: "",
        });
      });
    },
    onSearch(e) {
      console.log(e);
      // this.query.searchkey = e;
      let that = this;

      console.log(that.query);

      this.resetpage();

      that.fetch();
    },

    resetpage() {
      this.page = 1;
      const pagination = { ...this.pagination };
      pagination.current = 1;
      this.pagination = pagination;
    },

    setsiderdata() {
      let siderdata = [];
      let siders = global.getSider();
      let index = 0;
      let level = parseInt(this.form.getFieldValue("level"));
      siders.map((item) => {
        if (item.level.indexOf(level) > -1) {
          siderdata[index] = {
            title: item.title,
            key: item.key,
            main: true,
            radio: item.list ? false : true,
            rule: "0",
          };
          index++;

          if (item.list) {
            item.list.map((item2) => {
              if (item2.level.indexOf(level) > -1) {
                siderdata[index] = {
                  title: item2.title,
                  key: item2.key,
                  main: false,
                  radio: true,
                  rule: "0",
                };
                index++;
              }
            });
          }
        }
      });

      this.siderdata = siderdata;
    },
    handleChangePassworOk() {
      const that = this;
      this.form2.validateFields((err, values) => {
        console.log("Received values of form: ", values, err);
        const { password } = values;
        let postdata = {
          password: password,
          id: this.editid,
        };

        updatePassword(postdata).then((res) => {
          console.log("updatePassword:", res);
          let results = res.results;
          if (results.message == "1" || results.message == "0") {
            that.$message.success("修改成功");
            that.visiblePasswordModal = false;
            that.form2.resetFields();
            that.fetch();
          } else if (results.message == "2") {
            that.confirmLoading = false;
          }
          that.confirmLoading = false;
        });
      });
    },
    handleChangePasswordCancel() {
      this.visiblePasswordModal = false;
    },
    checkPassword(rule, value, callback) {
      // console.log(rule, value);
      const password = value;
      this.password = password;
      if (password.length >= 6 && password.length <= 18) {
        callback();
        return;
      }
      callback("密码长度控制在6到18个字符串!");
    },
    checkPassword2(rule, value, callback) {
      // console.log(rule, value);
      const password2 = value;
      const password = this.password;
      if (password2 != password) {
        callback("前后密码不一致!");
        return;
      }
      callback();
    },

    onPassword(record) {
      this.visiblePasswordModal = true;
      this.editid = record.id;
    },
    ruleonchange(e, key) {
      // console.log("key:", key);
      let rulevalue = e.target.value;
      let siderdata = this.siderdata;
      siderdata.map((item, index) => {
        // console.log(item.key);
        if (item.key == key) {
          siderdata[index].rule = rulevalue;
        }
      });
      this.siderdata = siderdata;
      // console.log("siderdata", this.siderdata);
    },
    showAdd(b) {
      console.log("showadd", b);
      return b;
    },
    handleFileChange(info) {
      console.log(info);

      let fileList = [...info.fileList];
      // fileList = fileList.slice(-2);
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList;
    },

    beforeImgUpload(file) {
      const that = this;
      const { form } = this;
      that.uploading = true;
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        that.$message.error("你只能上传jpeg或者png文件!");
      }
      console.log("file", file);
      const isLt2M = file.size / 1024 / 1024 < 0.4;
      if (!isLt2M) {
        that.$message.error("图片必须要小于 400KB!");
        that.uploading = false;
        return false;
      }
      getBase64(file, (imageUrl) => {
        that.imageUrl = "";

        that.uploading = false;
        // console.log("this.imageUrl:", this.imageUrl);
        // can use data-binding to set

        uploadImage({ img: imageUrl, filename: file.name, kind: "photo" }).then(
          (res) => {
            console.log(res);
            let results = res.results;
            if (results.message == "1") {
              that.$message.success("上传成功");
              that.imageUrl = global.baseUrl + results.url;
              setTimeout(() => {
                // alert(that.imageUrl);
                form.setFieldsValue({
                  photourl: that.imageUrl,
                });
              });
            } else if (results.message == "0") {
              that.$message.wrong("上传失败");
            }
          }
        );
      });
      return isJpgOrPng && isLt2M;
    },

    deleteAll() {
      const ids = this.selectedRowKeys.join(",");
      console.log("del:", ids);
      const that = this;
      this.$confirm({
        title: "提示",
        content: "确定删除这条题目?",
        okText: "删除",
        cancelText: "取消",
        onOk() {
          delUser({ id: ids }).then((res) => {
            console.log(
              "%cdel",
              "color: #FF0000;font-size: 18px;font-weight: bold;",
              res
            );
            that.fetch();
          });
        },
        onCancel() {},
      });
    },
    add() {
      this.modaltitle = "添加";
      this.treevalue = "";
      this.action = "add";
      this.editid = 0;
      (this.level = 1), (this.visibleAddModal = true);
      this.passwordshow = true;
      this.imageUrl = "";
      this.fileList = [];
      this.fetchUnit();
      setTimeout(() => {
        this.form.resetFields();
        this.classlist2 = [];
        this.handleProChange("210000");
      });
    },

    handleAddOk() {
      this.confirmLoading = true;
      const that = this;

      this.form.validateFields((err, values) => {
        console.log("Received values of form: ", values);

        if (!err) {
          console.info("success");

          let keysary = [];
          let rulesary = [];

          this.siderdata.map((item) => {
            keysary.push(item.key);
            rulesary.push(item.rule);
          });

          let lock = 0;
          if (values.lock == "checked") {
            lock = 1;
          }

          let citycode = values.citycode != undefined ? values.citycode : "";
          let discode = values.discode != undefined ? values.discode : "";
          // let unid = values.unid != undefined ? values.unid : "0";
          let unid = "0";
          let class1 = values.class1 != undefined ? values.class1 : [];

          let treevalue = that.treevalue;

          if (values.level != 4) {
            treevalue = [];
          }

          let postdata = {
            username: values.username,
            realname: values.realname,
            phone: values.phone,
            photourl: values.photourl,
            lock: lock,
            level: values.level,
            citycode: citycode,
            city: this.cityname,
            discode: discode,
            dis: this.disname,
            unid: unid,
            class1: class1,
            keys: keysary.join(","),
            rules: rulesary.join(","),
            class2s: treevalue,
            // mcode: values.mcode,
          };

          console.log("postdata:", postdata);

          // return false;

          if (this.action == "add") {
            postdata.password = values.password;
            addUser(postdata).then((res) => {
              console.log(res);
              let results = res.results;
              if (results.message == "1") {
                that.$message.success("添加成功");
                that.visibleAddModal = false;
                that.form.resetFields();
                that.fetch();
              } else if (results.message == "2") {
                that.confirmLoading = false;
                that.$message.warning("用户名重复！");
                return false;
              }
              that.confirmLoading = false;
              that.visibleAddModal = false;
            });
          } else if (this.action == "edit") {
            postdata.id = this.editid;
            updateUser(postdata).then((res) => {
              console.log(res);
              let results = res.results;
              if (results.message == "1") {
                that.$message.success("修改成功");
                that.visibleAddModal = false;
                that.form.resetFields();
                that.fetch();
              } else if (results.message == "2") {
                that.confirmLoading = false;
                that.$message.warning("用户名重复！");
                return false;
              }
              that.confirmLoading = false;
              that.visibleAddModal = false;
            });
          }
        } else {
          // that.$message.error("出现错误，请联系管理员！");
          // that.form.resetFields();
          that.confirmLoading = false;
        }
      });
    },

    handleAddCancel() {
      this.visibleAddModal = false;
    },

    onEdit(record) {
      this.modaltitle = "修改";
      this.visibleAddModal = true;
      this.passwordshow = false;
      console.log("record:", record);
      setTimeout(() => {
        this.form.resetFields();

        let keysary = record.keys.split(",");
        let rulesary = record.rules.split(",");

        setTimeout(() => {
          this.setsiderdata();
          let siderdata = this.siderdata;
          siderdata.map((item, index) => {
            keysary.map((item2, index2) => {
              if (item.key == item2) {
                siderdata[index].rule = rulesary[index2];
              }
            });
          });
          this.siderdata = siderdata;

          console.log("siderdata", this.siderdata);
        });

        let lock = false;
        if (record.lock == "1") {
          lock = "checked";
        }

        this.citycode = record.citycode;

        this.fetchUnit(record.citycode, record.unid);

        let class1 = record.class1 == [] ? [] : record.class1;
        console.log("class1", class1);

        // class1.map((item,index) => {
        //   class1[index] = parseInt(item);
        // });
        // console.log("class1", class1);

        this.level = record.level;

        this.form.setFieldsValue({
          username: record.username,
          realname: record.realname,
          phone: record.phone,
          lock: lock,
          photourl: record.photourl,
          level: record.level,
          // mcode: record.mcode,
        });

        this.handleCityChange(record.citycode, [], record.unid);
        this.handleDisChange(record.discode, [], record.unid);

        setTimeout(() => {
          this.form.setFieldsValue({
            citycode: record.citycode,
            discode: record.discode,
            unid: record.unid,
            class1: class1,
          });
        });

        this.treevalue = record.class2s;

        this.imageUrl = record.photourl;
        this.fileList = record.flist;

        console.log("fileList:", record.flist);

        // this.setClassList(record.class1, record.class2);
        this.action = "edit";
        this.editid = record.id;
      });
    },
    onDel(id) {
      console.log("del:", id);
      const that = this;

      this.$confirm({
        title: "提示",
        content: "确定删除这个用户?",
        okText: "删除",
        cancelText: "取消",
        onOk() {
          delUser({ id: id }).then((res) => {
            console.log(
              "%cdel",
              "color: #FF0000;font-size: 18px;font-weight: bold;",
              res
            );
            that.fetch();
          });
        },
        onCancel() {},
      });
    },

    onRecheck(id) {
      console.log("recheck:", id);
      const that = this;

      this.$confirm({
        title: "提示",
        content: "确定重置这个账户的登录验证信息?",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          reUserCheck({ id: id }).then((res) => {
            console.log(
              "%crecheck",
              "color: #FF0000;font-size: 18px;font-weight: bold;",
              res
            );
            that.fetch();
          });
        },
        onCancel() {},
      });
    },

    onMag(id) {
      console.log("mag:", id);
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination, filters, sorter);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.page = pagination.current;
      this.pagination = pager;
      // this.fetch({
      //   pagesize: pagination.pageSize,
      //   page: pagination.current,
      //   sortField: sorter.field,
      //   sortOrder: sorter.order,
      //   ...filters,
      // });
      this.fetch();
    },
    fetch(
      params = { page: this.page, pagesize: this.pageSize, query: this.query }
    ) {
      let that = this;
      this.loading = true;
      // console.log(params);
      fetchUser(params).then((res) => {
        console.log("fetchUser:", res);
        const pagination = { ...this.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = this.pageSize;
        that.loading = false;
        this.data = res.results.list;
        this.pagination = pagination;
      });
    },
    start() {
      this.loading = true;
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mtable {
  font-size: 14px;
}
.nmtitle {
  font-size: 12px;
}
.nmitem {
  font-size: 14px;
}
.tmimage {
  max-width: 200px;
  max-height: 200px;
  border: 5px solid #fff;
}
.photo img {
  height: 80px;
  width: 80px;
  background-color: #fff;
  padding: 5px;
  -o-object-fit: contain;
  object-fit: contain;
}
</style>
