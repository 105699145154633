<template>
  <div class="topbar">

    <div class="logo-1">

    </div>

    <div class="uinfo"  style=" position: relative; width:200px; height:60px; background-color:#fff; display:block;">

    </div>

    <div
      class="usermag"
      style="line-height: 24px; font-size: 14px"
      v-if="showback == '0'"
    >
      <span v-if="logininfo.ksa_login == 'true'"
        >&nbsp; <b>{{ logininfo.ksa_userName }}</b>
        &nbsp;(姓名:{{logininfo.ksa_realName}})&nbsp;&nbsp;&nbsp;&nbsp;
        <template v-if="logininfo.ksa_level == '0'"> 超级管理员 </template>
        <template v-if="logininfo.ksa_level == '1'"> 管理员 </template>
        <!-- <template v-if="logininfo.ksa_level == '2'">
          员工
        </template>
        <template v-if="logininfo.ksa_level == '3'">
          ({{ logininfo.ksa_unname }})管理员
        </template>
        <template v-if="logininfo.ksa_level == '4'">
          ({{ logininfo.ksa_unname }})考评人
        </template> -->
        &nbsp;&nbsp;</span
      >
      <a-dropdown v-if="logininfo.ksa_login == 'true'">
        <a
          class="ant-dropdown-link clickdrop"
          @click="(e) => e.preventDefault()"
        >
          <a-icon class="userphoto" type="user" />
          <a-icon v-if="logininfo.ksa_login == 'true'" type="down" />
        </a>
        <a-menu slot="overlay" v-if="logininfo.ksa_login == 'true'">
          <a-menu-item>
            <a href="javascript:;" @click="logout">
              退出&nbsp;&nbsp;
              <a-icon type="logout" />
            </a>
          </a-menu-item>
          <a-menu-item>
            <a href="javascript:;" @click="myuserinfo">
              个人中心
              <a-icon type="user" />
            </a>
          </a-menu-item>
          
        </a-menu>
      </a-dropdown>

    </div>
    <!-- <a-input placeholder="Basic usage" /> -->
  </div>
</template>

<script>
import { checklogin } from "../api/index";
import global from "../common";
export default {
  name: "TopBar",
  props: {
    msg: String,
    showback: String,
  },
  data() {
    return {
      logininfo: [],
    };
  },
  created() {
    this.logininfo = global.getLoginA();
    console.log("logininfo:", global.getLoginA());
    console.log("logininfo:", this.logininfo.ksa_login);
    console.log("this.$route.path",this.$route.path);
    if (this.logininfo.ksa_login == "true" && this.$route.path == "/login") {
      this.$router.push("/index");
      return false;
    }
    if (this.logininfo.ksa_login == "false" && this.$route.path != "/login") {
      this.$router.push("/login");
      return false;
    }
    if (this.$route.path != "/login") {
      this.checklogin();
      return false;
    }
  },
  methods: {
    downfile(index) {
      let url='';
      if(index==1){
        url='//218.60.149.68/ks/files/lct1.jpg';
      }else if(index==2){
        url='//218.60.149.68/ks/files/lct2.jpg';
      }else if(index==3){
        url='//218.60.149.68/ks/files/wendang.docx';
      }
      window.open(url);
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      console.log("collapsed:",this.collapsed);
    },
    myuserinfo(){
      this.$router.push("/my/userinfo");
    },
    checklogin() {
      const that = this;
      checklogin().then((res) => {
        console.log("checklogin:", res);
        if (res.results.message == 10001) {
          this.$error({
            title: "错误",
            content: "登录超时,请重新登录!",
            onOk() {
              global.clearLoginInfo();
              global.clearTdata();
              that.$router.push("/login");
            },
          });
        } else if (res.results.message == 10002) {
          this.$error({
            title: "错误",
            content: "您已经被锁定,请更换账号重新登录!",
            onOk() {
              global.clearLoginInfo();
              global.clearTdata();
              that.$router.push("/login");
            },
          });
        }
      });
    },
    gologin() {
      this.$router.push("/login");
    },
    gotiaoshi() {
      this.$router.push("/tiaoshi");
    },
    logout() {
      global.clearLoginInfo();
      global.clearTdata();
      this.$router.push("/login");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.showbackdiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}
.showback {
  background-color: #fd711c;
  border-color: #fd711c;
  font-size: 14px;
}

.gofront {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
}
.logo-1 {
  background-image: url(../assets/limgs/logo_black.png);
  /* background-image: none; */
  background-position: center;
  background-size: contain;
   background-size: 180px !important;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 200px;
  margin-left: 0px;
  /* background-color: #f9f9f9; */
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>
